.imagegallary {
  position: relative;
  width: 100%;
  margin: 20px auto;
  columns: 4;
  column-gap: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.imagegallary .box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}
.imagegallary .box img {
  max-width: 100%;
  aspect-ratio: auto;
  object-fit: cover;
  cursor: pointer;
  /* mix-blend-mode: exclusion; */
}
.box img:hover {
  mix-blend-mode: normal;
}

.imagegallery_carsol {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background-color: #00000088;
  backdrop-filter: blur(10px);
  height: 91%;
  border-radius: 5px;
}
.close {
  position: absolute;
  top: 5px;
  right: 20px;
  color: #ffcc00;
  font-size: 40px;
  /* z-index: 5; */
}
.left__circle {
  padding: 0 50px 0 0;
  color: rgba(255, 255, 255, 0.644);
  font-size: 60px;
}
.right_circle {
  padding: 0 0 0 50px;
  color: rgba(255, 255, 255, 0.644);
  font-size: 60px;
}
.imgBox {
  width: 65%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgBox img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .imagegallary {
    width: calc(100% - 40px);
    columns: 3;
  }
}
@media (max-width: 767px) {
  .imagegallary {
    columns: 2;
  }
}
@media (max-width: 767px) {
  .imagegallary {
    columns: 1;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  margin: 0 2px;
}

.pagination button.active {
  background-color: #007bff;
  border-color: #007bff;
  color: #ffffff;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination button:hover {
  background-color: #f0f0f0;
}
