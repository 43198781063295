.culturevalues_section_1{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-light);
}
.culturevalues_section_1 p{
    width: 60%;
    padding: 48px;
    font-size: 19.434px;
    line-height: 25px;
    text-align: center;
}
.culturevalues_data_heading{
    font-style: normal;
    font-weight: 900;
    font-size: 34.52px;
    line-height: 11px;
    color: #333333;

}
.culturevalues_data .culturevalues_sub_paragraph{
    width: 80%;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    /* background-color: blue; */
}
.culturevalues_data__left{
    padding: 0 0 0 120px;
}
.culturevalues_data__left .culturevalues_sub_paragraph{
    width: 80%;
    font-style: normal;
    font-weight: 400;
    font-size: 18.4044px;
    line-height: 23px;

}
.culturevalues_section_1 small{
    font-weight: 700;
    font-size: 19.434px;
    line-height: 25px;
    color:var(--gray);
}
.culturevalues_section_2{
    width: 100%;
    background-image: url("../../assets/images/CultureValues/values1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.culturevalues_sub_heading span{
    border-bottom: 5px solid black;
    padding: 0 0 4px 0;

}
.culturevalues_contant{
    display: flex;
    justify-content: center;
    align-items: center;
}
.culturevalues_contant p{
width: 100%;

}
.culturevalues_section_2 img{
    width: 250px;
}
.culturevalues_contant_img img{
    width: 100%;

}
.culturevalues__image__right_side img{
    width: 85%;
    padding: 0 0 0 150px;

}



@media screen and (max-width:767px) {

    .culturevalues_section_1 p {
        width: 100%;
    }
    .culturevalues_data .culturevalues_sub_paragraph {
        width: 100%;
    }
    .culturevalues_contant_img img {
        width: 100%;
        height: 100%;
        /* padding: 123px 0 0 0; */
    }
    .culturevalues_contant{
        display: flex;
        flex-direction: column;
    }
    .culturevalues__image__right_side img{
        width: 100%;
        padding: 0;
    
    }
    .culturevalues_data__left {
        padding: 0;
    }

    .culturevalues_contant_img img{
        order:1;
    }
    .culturevalues_data{
        order:2;
    }
    .culturevalues_contant p {
        margin-top: 10px;
        font-size: 24px;
        /* text-align: center; */
    }

}

@media screen and (width:768px) {
    .culturevalues_section_1 p {
        width: 100%;
        padding: 39px;
    }
    
}

@media screen and (width:1024px){
    .culturevalues_section_1 p {
        width: 100%;
        padding: 48px;
    }
    
}
