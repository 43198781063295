.workin_main{
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-unpure);
}
.workin_main img{
    width: 100%;
    height: 100%;
    box-shadow: 5px 5px 20px #000;

}