.breadcrump_main {
  position: relative;
  width: 100%;
  height: 6vh;
  display: flex;
  align-items: center;
  background: #f4f4f4;
  padding: 0 100px;
  /* margin-top: 60px; */
  margin-bottom: 40px;
}
.breadcrumb_ul {
  padding-top: 14px;
  display: flex;
  align-items: center;

  list-style: none;
}
.breadcrumb_items {
  color: #000;
}
.breadcrumb_items a {
  padding-right: 10px;
  text-decoration: none;
}
.breadcrumb_items a span {
  color: #131313;
  padding: 0 4px;
}

@media (max-width: 480px) {
  .breadcrump_main {
    display: none;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .breadcrump_main {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .breadcrump_main {
    /* margin-top: 16px; */
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .breadcrump_main {
    /* margin-top: 106px; */
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .breadcrump_main {
    /* margin-top: 133px; */
  }
}
@media (min-width: 1300px) and (max-width: 1399px) {
  .breadcrump_main {
    /* margin-top: 94px; */
  }
}
@media (min-width: 1400px) and (max-width: 1919px) {
  .breadcrump_main {
    /* margin-top: 80px; */
  }
}
@media (min-width: 1920px) {
  .breadcrump_main {
  }
}
