.overview_main{
    width: 100vw;
    margin-top: 22px;
    color:#333e49;
    background-color: #f4f4f4;
    padding: 0 107px;
}
.description{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sharejob{
 border-style: none;   
 background:none;
 color:var(--blue);
}

.Upertext p{
    line-height: 0.6; 
}
.Upertext .paragraph{
    line-height: 1.6;
    height: 120px;;
}
.text_decoration_span{
    font-size: 20px;
    font-weight: 600;
}
.P_text{
    font-size: 20px;
}
.paragraph{
    font-size: 20px;
}
.website{
    color: var(--blue);
}
.apllybutton .Apllyjob{
width: 740px;
height: 50px;
background-color: #333333;
color: white;
border-radius: 7px;
font-size: 20px;
}
.Upertext .apllybutton{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-device-width: 768px)
and (max-device-width: 1024px)  and (orientation: portrait) {
    .Upertext p {
        line-height: 1.6;
    }
}

@media screen and (min-device-width: 768px)
and (max-device-width: 1024px)  and (orientation: landscape) {
    .Upertext p {
        line-height: 1.6;
    }
}

@media screen and (max-device-width: 480px){

    .overview_main {
        width: 100vw;
        margin-top: 22px;
        color: #333e49;
        background-color: #f4f4f4;
        padding: 0 22px;
    }
    .Upertext p {
        line-height: 1.6;
    }
}