.odyssey_main {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.linkdin_post p {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 39px;
  color: var(--gray);
}
.odyssey_main_img {
  width: 65%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.odyssey_main_img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
  border-radius: 5px;
}

.odyssey_main .odyssey_main_img img {
  width: 100%;
}
.odyssey_main_data {
  display: flex;
  justify-content: center;
  align-items: center;
}
#gototop {
  z-index: 2;
  top: 60px;
  left: 0;
  position: sticky;
  background-color: rgba(255, 255, 255, 0.356) !important;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
}

.linkdin_post button {
  padding: 10px;
  border: none;
  width: 150px;
  background: var(--off-white);
  border-radius: 2px;
}
.next_button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.next_button button {
  border-radius: 5px;
  border: none;
  background: var(--off-white);
  font-weight: 600;
  left: calc(50% - 141px / 2 + 0.5px);
  padding: 14px 57px;
}
.next_button button a {
  color: #000;
}
.timeline {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0;
  width: 80%;
}
.timeline .dot {
  width: 35px;
  height: 20px;
  border-radius: 50%;
  background-color: #a1a1a1;
  gap: 0;
  padding: 0;
}
.timeline .pipe {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 2px;
  background-color: #a1a1a1;
  gap: 0;
  padding: 0;
  margin: 0;
}
.timeline .pipe.active {
  background-color: #131313;
}
.timeline .dot.active {
  background-color: #131313;
}

.pipe p {
  color: #131313;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 9.139px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.new_section_post {
  /* background-color:#131313; */
  background: #fff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: none;
  margin-bottom: 22px;
}

.new_post {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 500px;
  width: 400px;
  border-radius: none;
}
.post_url {
  margin: 35px;
  box-shadow: -5px 7px 37px 10px rgba(180, 180, 180, 0.297);
  -webkit-box-shadow: -5px 7px 37px 10px rgba(180, 180, 180, 0.341);
  -moz-box-shadow: -5px 7px 37px 10px rgba(176, 173, 173, 0.314);
  border-radius: 8.304px;
  border: 1.038px solid rgba(0, 0, 0, 0.08);
  background: #fff;
}

@media screen and (max-width: 767px) {
  .odyssey_main_section {
    display: grid;
    place-items: center;
  }

  .odyssey_main .odyssey_main_img {
    order: 1;
  }
  .odyssey_main .odyssey_main_data {
    order: 2;
  }
  .odyssey_main_img {
    width: 100%;
  }
  .linkdin_post p {
    text-align: justify;
    font-size: 18px;
  }
  .timeline {
    width: 100%;
  }
  .new_post {
    columns: 1;
  }
}

@media screen and (width: 820px) {
  .odyssey_main {
    /* flex-direction: row; */
  }
  .odyssey_main_img {
    width: 50%;
  }
  .odyssey_main_img img {
    height: 20vh;
    /* height: 264px; */
  }
  .odyssey_main_data {
    width: 50%;
  }
  .linkdin_post p {
    font-size: 20px;
  }
  .new_post {
    columns: 2;
  }
}

@media screen and (width: 1023px) {
  .odyssey_main {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .odyssey_main_img {
    width: 50%;
  }
  .odyssey_main_data {
    width: 50%;
  }
  .new_post {
    columns: 2;
  }
}
