@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --white: #ffffff;
  --white-transparent: #ffffffa7;
  --white-light: #f5f5f5;
  --white-lighter: #f8f8f8;
  --white-unpure: #f0f0f0;
  --off-white: #e0e0e0;
  --white-dark: #131313;
  --black: #000000;
  --black-dark: #131313;
  --gray: #333333;
  --gray-dark: #383a3b;
  --gray-light: #585858;
  --yellow: #e7be0d;
}

/* ::-webkit-scrollbar{
    width: 10px;
} */
/* ::-webkit-scrollbar-track{
    background-color: #000;
} */
/* ::-webkit-scrollbar-thumb{
background: #ffcc00;
border-radius: 7px;
border: 2px solid #000;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  font-size: 16px;
}
html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
