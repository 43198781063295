.retail_video_animation {
  width: 100%;
  height: 35vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 41px;
  margin-top: 5px;
  border: none;
  outline: none;
}
.retail_video_animation video {
  object-fit: cover;
  border: none;
  outline: none;
}

.retail_video_animation_size {
  width: 1700px;
  height: 270px;
}

.retail__card {
  width: 315.84px;
  height: 466px;
  border-radius: 5px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
.card_section {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -55px;
}
.card_section {
}

.retail__card_image_main {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 280.64px;
  height: 280.64px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
.retail__card_image_main img {
  width: 136px;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.retail_image_slider {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  height: 50vh;
  border-radius: 5px;
}
.retail_data {
  display: flex;
  color: aliceblue;
  flex-wrap: wrap;
  height: 257px;
}
.retail_image {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.retail_image_arrow {
  color: aliceblue;
  display: flex;
  justify-content: flex-end;
  align-items: end;
}
.retail_image_arrow span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  color: var(--black);
  width: 40px;
  height: 40px;
  margin-left: 5px;
  font-size: 25px;
  border-radius: 50%;
}
.retail_image_arrow span:nth-child(1) {
  background: var(--gray);
}

.retail__card_body {
  width: 90%;
  height: 30vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.retail__card_body .retail__card_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  /* overflow: scroll; */
  overflow-x: hidden;
  overflow-y: hidden;
}
.retail__card_title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #333333;
}
.retail_text {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.retail_text span {
  font-weight: 900;
  font-size: 49px;
  color: #333333;
}
.retail_text p {
  font-weight: 400;
  font-size: 49px;
  color: #333333;
}

/* //New css */
.Retail_Main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}
.Retail_Main .Retail_Image {
  /* width: 500px; */
  height: 200px;
  /* background-color: red; */
}
.Retail_Image {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 10px; */

  border: 0.917px solid #e7e7e7;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  /* padding: 20px; */
  background: #fff;
  /* margin: 10px; */
  position: relative;
}
.Retail_Image:hover {
  border: 0.917px solid #f5eb8f;
  box-shadow: 0px 0px 8px 0px #f5eb8f;
  transform: scale(1.1);
  transition: 0.3s ease-out;
}

.Retail_Image img {
  width: 10vw;
  height: 15vh;
  object-fit: contain;
}
.Retail_Image strong {
  color: #333;
  font-family: Poppins;
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Retail_Image label {
  position: absolute;
  top: 0;
  left: 0;
  color: #989898;

  font-family: Poppins;
  font-size: 20.037px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* .main-retail-Lido {
  gap: 50px;
} */
.retail_Lido_Main {
  /* height: 20vw; */
  overflow: hidden;
  /* gap: 20px; */
}
.Retail_Lido_Image {
  /* background-color: #333; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 150px;
}
.Retail_Lido_Image strong {
  color: #333;

  font-family: Poppins;
  font-size: 37.494px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Retail_Lido_Image img {
  width: 15vw;
}

.Retail_Lido_Description {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #e7be0d;
  flex-direction: column;
  padding: 50px;
}
.Retail_Lido_Description1 {
  position: relative;

  display: flex;
  justify-content: center;
  background-color: #000000;
  flex-direction: column;
  padding: 50px;
}
.Retail_Lido_Description2 {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #e0e0e0;
  flex-direction: column;
  padding: 50px;
}
.retail-page-bg-png {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.2;
}
.retal_card_Heading {
  color: #181818;
  font-family: Poppins;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 48.371px;
}
.retal_card_Heading1 {
  color: #fff;
  font-family: Poppins;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 48.371px;
}
.retail_card_description {
  color: #181818;
  font-family: Poppins;
  font-size: 18.155px;
  font-style: normal;
  font-weight: 500;
  line-height: 38.346px;
  z-index: 1;
}
.retail_card_description1 {
  color: #fff;
  font-family: Poppins;
  font-size: 18.155px;
  font-style: normal;
  font-weight: 500;
  line-height: 38.346px;
  z-index: 1;
}
/* new css */
.retail_image_arrow span:nth-child(1):hover {
  background: var(--white);
}
@media screen and (max-width: 767px) {
  .retail_image_slider {
    margin: 1px;
  }
  .card_section {
    margin-left: 0;
  }
  .retail_image img {
    width: 100%;
    height: 18vh;
    padding: 23px;
  }
  .retail_data h3 {
    font-size: 18px;
    font-weight: 800;
  }
  .retail_data p {
    font-size: 8px;
  }
  .retail_text p {
    font-size: 20px;
    font-weight: 600;
  }

  .retail__card_title {
    font-weight: 600;
  }
  .retail__card_parent {
    display: flex;
    margin: 10px;
  }
  .retail__card {
    width: 100%;
  }
  .Retail_Lido_Image {
    padding: 0;
  }
  .Retail_Image img {
    width: 50vw;
  }
  .Retail_Lido_Image img {
    width: 50vw;
    padding-bottom: 10px;
  }
}

@media screen and (width: 820px) {
  .retail_image img {
    width: 100%;
    padding: 70px;
  }
  .retail__card_parent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .retail__card {
    width: 422.84px;
    height: 412px;
  }
  .retail__card_image_main {
    width: 370.64px;
    height: 556.64px;
  }
  .retail__card_body .retail__card_text {
    font-size: 15px;
    line-height: 19px;
  }
}

@media screen and (width: 768px) {
  .retail_image img {
    width: 100%;
    padding: 70px;
  }
  .retail__card_parent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .retail__card {
    width: 422.84px;
    height: 412px;
  }
  .retail__card_image_main {
    width: 370.64px;
    height: 556.64px;
  }
  .retail__card_body .retail__card_text {
    font-size: 15px;
    line-height: 19px;
  }
  .Retail_Lido_Image {
    padding: 0;
  }
  .retail_card_description {
    font-size: 12px;
    line-height: 20px;
  }
  .retail_card_description1 {
    font-size: 12px;
    line-height: 20px;
  }
  .Retail_Lido_Image img {
    width: 24vw;
  }
  .Retail_Image img {
    /* width: 20vw; */
    width: 26vw;
    height: 11vh;
  }
  .retail_text {
    font-size: 45px;
  }
}

@media screen and (width: 1024px) {
  .retail__card_parent {
    margin-right: 20px;
  }
}

@media screen and (width: 1180px) {
  .retail__card_parent {
    margin-right: 20px;
  }
}
