.service_banner {
  /* background-color: #333; */
  width: 100%;
  height: 30vh;

  background-image: url('../../assets/banner/image\ 83.png');
  background-position: center;
}
.line {
  width: 2px;
  height: 60px;
  color: #000;
  background: #000;
}
.service_xstore_button_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  transition: hover 0.3s ease-in-out;
}
.service_xstore_button_content:hover .boxydesign {
  display: flex;
  list-style: circle;
}
.service_xstore_button_content:hover .boxydesignpro {
  display: none;
}
.boxydesign {
  /* overflow: hidden; */
  display: none;
  font-weight: 600;
  width: 100%;
}
.boxydesignpro {
  overflow: hidden;
  font-weight: 600;
}
.boxydesign2 {
  border: 1px solid #000;
  padding: 5px 10px;
  margin-right: 10px;
  background-color: #f6c82a;
  font-size: 1.5rem;
}
.service_banner .shadow {
  position: absolute;
  /* backdrop-filter: blur(10px); */
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.service_banner .service_banner_title,
.service_banner_small {
  color: #fff;
}
.service_banner2 {
  /* background-color: #333; */
  width: 100%;
  height: 45vh;

  background-image: url('../../assets/banner/image\ 83.png');
  background-position: center;
}
.service_banner2 .shadow {
  position: absolute;
  /* backdrop-filter: blur(10px); */
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.service_banner2 .service_banner2_title,
.service_banner2_small {
  color: #fff;
}
.service_banner2 .service_banner2_button {
  border: 1px solid #fff;
  outline: none;
  background: #ffffff;
  padding: 10px 20px;
  margin-top: 20px;
  color: #000;
}

/* service main section-1 */
.serviceMainImg {
  width: 100%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.serviceMainImg img {
  width: 100%;
  object-fit: contain;
}

/* serction-2 services-btn */

.services__heading {
  width: 100%;
  height: 70vh;
  margin: 0 0;
  background: #fff;
  position: relative;
}
.services__heading_data {
  min-height: 250px;
  background: #000;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.services__heading .services__heading_intro {
  padding-top: 10px;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}
.services__heading .services__heading_subinfo {
  width: 46%;
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  color: #fff;
}

.services__button_group {
  margin: 0;
  padding: 0;
  width: 100%;
  position: absolute;
  top: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btncontainer {
  width: 30%;
}
.services__button_group Link {
  width: 100%;
  text-decoration: none;
}
.services__button_group button {
  margin: 10px 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  outline: none;
  border: none;
  box-shadow: -1px 1px 5px 2px gray;
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
}
.services__button_group .service_consumer_tech_button_parent:hover,
.services__button_group .xstorebtn:hover {
  scale: 1.1;
  border: 2px solid orange;
}

.services__button_group button .service_consumer_tech {
  height: 100px;
  padding-bottom: 20px;
}
.services__button_group button .service_xstore_img {
  height: 100px;
  padding-bottom: 20px;
}

/* Main Section */
.service_main_container {
  background: #fff;
}

.service_main_data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Oracle_mainSection1 {
  width: 100%;
  display: flex;
}
.service__product_img {
  width: 20%;
  /* object-fit: contain; */
  aspect-ratio: 3/4;
  margin: 0 30px 0 60px;
}

.Oracle_mainSection1_content {
  /* background: #000; */
  width: 80%;
}
.service_process_data {
  margin-top: 30px;
  margin-left: 60px;
}
.service_main_data strong {
  border-bottom: 2px solid black;
  font-size: 24px;
}
.service_heading_data {
  padding: 0 0 5px 0;
  font-size: 1.8rem;
}
.bulletLine_service_Subheading1_data {
  margin-left: 50px;
  color: #333;
  line-height: 25px;
}
.bulletLine li {
  font-size: 15px;
}

.service_Subheading_data {
  margin-top: 20px;
  color: #333;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.233px;
  width: 93%;
}
.service_Subheading1_data {
  margin-top: 20px;
  color: #333;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.233px;
  /* text-align: justify; */
}
.service_subheading1_data {
  margin-top: 30px;
  color: #333;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  width: 93%;
}
.caseStudyIntro {
  width: 90%;
  margin-top: 30px;
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.1rem;
}

.bulletLine {
  list-style-position: outside;
  color: #333;
  line-height: 25px;
  list-style-position: outside;
}

.service__consumertech_data span {
  font-size: 20px;
  font-weight: 900;
}
.diagram_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.service__product_img_Diagram {
  width: 60%;
  aspect-ratio: 16/9;
  object-fit: contain;
}
.caseStudyMain {
  width: 100%;
  padding-left: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 20px;
  /* background: -webkit-linear-gradient(red, yellow); */
}
.caseStudyMain span {
  width: 40%;
  min-height: 100px;
  background-color: #f0f0f0;
  padding: 5px 10px;
}
.caseStudyMain .Specialspan {
  margin-right: 50px;
}
.otherSection {
  width: 100%;
  display: flex;
  margin-bottom: 50px;
}
.otherSectionHeading {
  font-size: 1.8rem;
}
/* .otherSectionHeading::after { */
/*   content: ""; */
/*   border-top: 2px solid var(--primaryColor); */
/* } */
.otherSectionSpan {
  width: 80%;
  min-height: 100px;
  background-color: #eee;
  padding: 5px 10px;
}
.otherSectionContent_para {
  width: 80%;
  font-size: 1.2rem;
}
.otherSection img {
  width: 20%;
  aspect-ratio: 3/4;
  margin: 0 30px 0 60px;
}
.Explore_text {
  font-weight: 900;
}

.bulletLine_service_Subheading1_data li {
  font-size: 15px;
}
@media (width<=461px) {
  .services__button_group {
    display: none;
  }
}
@media (width<=768px) {
  .services__heading .services__heading_subinfo {
    width: 90%;
  }
  .services__heading_data {
    min-height: 60vh;
  }
  .services__button_group {
    top: 65%;
  }
  .service_xstore_img {
    width: 100px;
  }
  .btncontainer button {
    height: 200px;
  }

  .service_consumer_tech {
    width: 80px;
    height: 60px;
  }
  .caseStudyIntro {
    margin: 0 40px;
    text-align: left;
  }
  .caseStudyMain {
    margin: 20px 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .caseStudyMain span {
    width: 90%;
    margin: 0;
  }
  .caseStudyMain .Specialspan {
    margin: 0;
  }
  .otherSection {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .otherSectionImg {
    width: 100%;
    min-width: 60vw;
    max-height: 500px;
    /* object-fit: contain; */
    aspect-ratio: 3/4;
  }
  .otherSectionContent {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .otherSectionContent_para {
    width: 90%;
    padding: 0;
    text-align: left;
  }
  .otherSectionSpan {
    width: 90%;
    margin: 20px 0;
    padding: 0;
  }
}
@media (width<=998px) {
  .Oracle_mainSection1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .service__product_img {
    margin: 10px 0;
    min-width: 300px;
    aspect-ratio: 3/4;
  }
  .Oracle_mainSection1_content {
    width: 90%;
    text-align: center;
  }
  .bulletLine_service_Subheading1_data {
    margin: 0;
  }
  .service_process_data {
    margin: 20px 40px;
  }
  .bulletLine_service_Subheading1_data {
    margin: 0 40px;
  }
  .bulletLine {
    text-align: left;
    margin: 0 10px;
  }
  .service_banner,
  .service_banner .shadow {
    height: 20vh;
  }
  .service_banner2,
  .service_banner2 .shadow {
    height: 30vh;
  }
  .service_banner .service_banner_title {
    text-align: center;
    font-size: 25px;
  }
  .service_banner2 .service_banner2_title,
  .service_banner2_small {
    text-align: center;
  }
  .service_banner2_small {
    margin-bottom: 20px;
  }
  .service_banner2 .service_banner2_title {
    font-size: 28px;
    margin-bottom: 10px;
  }
}
