.VideoGallary{
    width: 1400px;
    margin: 20px auto;
    columns: 4;
    column-gap: 20px;
}
.VideoGallary .box{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}
.VideoGallary .box img{
    max-width: 100%;
    border-radius: 15px;
}




@media (max-width : 1200px) {
    .VideoGallary{
        width: calc(100% - 40px);
        columns: 3;
    }
    
}
@media (max-width : 767px) {
    .VideoGallary{
        columns: 2;
    }
    
}
@media (max-width : 767px) {
    .VideoGallary{
        columns: 1;
    }
    
}