.CompanyNote_container{
    width: 100%;
    background-color: brown;
    height: 40vh;

}

.main_container{
    display: flex;
    width: 100%;
    position: relative;

}
.company_image img{
    
    position: absolute;
    aspect-ratio: 19/9;
    width: 100%;
    object-fit: cover;
    height: 40vh;
}
.companyHeading{
    padding: 20px;
    margin-top: 135px;
    background-color: rgba(248, 248, 248, 0.337);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.companyHeading span{
    font-size: 50px;
}
.data{
    background-color: blue;
    margin: 30px;
}
.data span{
    margin-top: 30px;
}