.error_main{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-unpure);
}
.error_main img{
    width: 50%;
    height: 50%;
    box-shadow: 5px 5px 20px #000;

}