.vision_section_1{
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: var(--white-light);
}

.vision_section_1 p{
    width: 60%;
    color:var(--gray);
    padding: 48px;
    font-weight: 400;
    font-size: 20px;
    line-height: 44px;
    color: #333333;
    text-align: center;
}
.vision_card{
    display: flex;
    justify-content: center;
    gap: 30px;
}
.vision_section_1 span{
    font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 44px;
color: #333333;
}
.vision_section_3{
    display: flex;
    flex-direction: column;
}
.vision_main .vision_section_3{
    height: 50vh;
    border: 3px solid #000;
    word-wrap: break-word;
}
.vision_section_3 img{
    width: 100%;
    height: 30vh;
    border-bottom: 1.5px solid #000 ;
}
.vision_card_content{
    width: 100%;
}
.vision_button button{
    width: 94px;
    height: 28px;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.04em;
    box-sizing: border-box;
    color: #333333;
    border: 1px solid #131313;
    border-radius: 2px;
    margin: 5px 0;
}
@media screen and (max-width:767px) {
    .vision_section_1 p{
        width: 100%;
        font-size: 17px;
        line-height: 36px;
        
    }
    
}

@media screen and (width: 768px) {
    .vision_section_1 p {
        padding: 0;
    }
    
}

@media screen and (width:820px) {
    .vision_section_1 p{
        padding: 0;
    }
    
}

@media screen and (width:1024px){
    .vision_section_1 p {
        width: 100%;
        padding: 20px;
    }
}

@media screen and (width:1180px) {
    .vision_section_1 p {
        width: 83%;}
    
}