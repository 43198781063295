.career_banner {
  /* background-color: #333; */
  width: 100%;
  height: 30vh;
  background-image: url("../../assets/banner/image\ 83.png");
  background-position: center;
}
.career_banner .shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.career_banner .career_banner_title,
.career_banner_small {
  color: #fff;
}
.career_banner .career_banner_button {
  border: 1px solid #fff;
  outline: none;
  background: #ffffff;
  padding: 10px 20px;
  color: #000;
}
/*Top banner End */
.career_banner2 {
  /* background-color: #333; */
  width: 100%;
  height: 30vh;
  background-image: url("../../assets/banner/image\ 83.png");
  background-position: center;
}
.career_banner2 .shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.career_banner2 .career_banner_title,
.career_banner_small {
  color: #fff;
}
.career_banner2 .career_banner_button {
  border: 1px solid #fff;
  outline: none;
  background: #ffffff;
  padding: 10px 20px;
  color: #000;
  margin-top: 30px;
}
/*Bottom banner End */
.career_img_story {
  width: 100%;
  position: relative;
}
.career_img_story img {
  width: 100%;
  max-height: 350px;
  aspect-ratio: 16/9;
  object-position: center;
  object-fit: cover;
}
.career_story_title_container {
  position: absolute;
  bottom: 0;
  left: 120px;
}
.career_story_title_container .career_story_title {
  color: #e7be0d;
  font-size: 30px;
  font-weight: 900;
  text-shadow: 1px 1px 2px #0066ff9a;
  background-color: #0000002b;
}
.career_dot {
  color: #e7be0d;
  font-size: 40px;
}
.career_story_area {
  padding: 0 90px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  /* new line add subodh */
  flex-direction: column;
}
/* new add subodh */
.career_story_area span {
  color: #e7be0d;
  font-size: 30px;
  font-weight: 900;
  /* text-shadow: 1px 1px 2px #0066ff9a; */
}

.career_story_section {
  padding: 0 40px;
}
.career_story_title {
  width: 100%;
  color: var(--black);
  font-size: 30px;
  font-weight: 900;
}
.career_story_title .career_firstLetter {
  color: var(--off-white);
}

.paragraphDetail {
  overflow: hidden;
  color: #333;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31.4px;
}
.paragraph .showmore {
  color: #0066ff;
  font-weight: 600;
}

.career_job_section {
  background-color: #f3f3f3;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin-top: 40px; */
  width: 100%;
  height: auto;
}
.career_job_title {
  margin: 20px 0;
  text-align: center;
}
.career_job_title span {
  font-weight: 700;
  letter-spacing: 0.05rem;
  font-size: 3rem;
}
.career_job_area {
  display: flex;
  justify-content: center;
  align-items: center;
}
.career_job_area2 {
  margin-bottom: 40px;
}
.career_job_area_btn {
  background-color: #333333;
  padding: 2px 15px;
  width: 90px;
  color: var(--white);
}
.career_job_card {
  width: 85%;
  background-color: var(--white);
  margin: 9px 9px;
  padding: 20px;
  border: 0.2px solid var(--gray-light);
  box-shadow: 1px 1px 2px #ccc;
}
.career_job_card:hover {
  background-color: #e7be0d;
}
.career_team_section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}
.career_team_title {
  margin: 20px 0;
  font-size: 35px;
  text-align: center;
}
.career_team_title_bold {
  font-weight: 900;
  font-size: 37px;
}
.career_team_area {
  display: flex;
  justify-content: center;
}
.career_team_card img {
  width: 100%;
  border-radius: 7px;
}
.career_team_value {
  background-color: #e7be0d;
  width: 100%;
  margin: 50px 0;
}
.career_team_value_title {
  margin: 20px 0;
  text-align: center;
}
.career_team_value_title p {
  font-weight: 500;
  font-size: 35px;
}
.career_team_value_title p span {
  font-weight: 900;
  font-size: 35px;
}
.career_team_value_title span {
  color: var(--black);
}
.career_team_value_title_animation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.animation_card {
  display: flex;
  margin: 0 15px;
}
.animation_card_left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 15px;
}
.animation_card_left img {
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
}

.rotateimg {
  transform: rotate(180deg);
}
.animation_card_left > small {
  text-align: center;
  color: #000;
  font-size: 1.2rem;
}
.animation_card_right {
  color: #7f8180;
  font-size: 20px;
  margin-top: 10px;
}
.career_team_value_bottom {
  text-align: center;
  font-size: 25px;
  margin-bottom: 40px;
}
@media (width<=767px) {
  .career_story_area {
    padding: 0 10px;
  }
  .career_team_card {
    width: 95%;
  }
  .career_team_value_title_animation {
    display: none;
  }
  .career_team_value_bottom {
    margin: auto;
    text-align: left;
  }
  /* Subodh */
  .career_img_story img {
    width: 100%;
    height: 189px;
  }
  .career_banner2,
  .career_banner2 .shadow {
    height: 35vh;
  }

  .career_banner_title,
  .career_banner_small {
    text-align: center;
  }
}
