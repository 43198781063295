.application_main{
    margin-top: 31px;
    color:#1b1b1b;
    background-color: #f4f4f4;
    padding: 0 107px;
}
.personal_Info_heading{
display: flex;
justify-content: space-between;
align-items: center;
line-height: 0.1;
margin-top: 41px;
}
.personal_info_text{
    font-size: 34px;
}
.text_box_phone{
    border: 2px solid #131313;
    border-radius: 4px;
}
.name{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.text_box{
    width: 430px;
    border-radius: 4px;
    height: 40px;
}
.text_box_email{
    width: 100%;
    border-radius: 4px;
    height: 40px;  
}
.react-tel-input .form-control{
    width: 100%;
    height: 40px;

}
.resume_file{
    margin: 30px;
}
.resume_text{
    padding: 110px 340px;
}
.education{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.button_add{
    width: 84.92px;
height: 31.75px;
border: 0.992138px solid #000000;
border-radius: 7.93711px;
}
.text_box_summary{
    width: 100%;
    border-radius: 4px;
    height: 120px;  
}


.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
 }
.files{ position:relative}
.files:after {  pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}
.color input{ background-color:#f1f1f1;}
.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}

.apllybutton{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Apllyjob{
    width: 740px;
    height: 44px;
    background-color: black;
    color: white;
    border-radius: 7px;
}
.input_area{
    width: 100%;
    border-radius: 4px;
    height: 40px;
    margin-left: 5px;
    padding-left: 5px;

}

.InputNumber{
    display: flex;
    position: relative;
    margin: 10px 0;
}
.InputNumber img {
    border: 1px solid #000;
    padding: 3px;
    width: 40px;
    height: 40px;
    border-radius: 4px;

}

@media screen and (min-device-width: 768px)
and (max-device-width: 1024px)  and (orientation: portrait) {
    .text_box {
        width: 244px;
        border-radius: 4px;
        height: 40px;
    }
    .personal_info_text {
        font-size: 28px;
    }
    
}

@media screen and (min-device-width: 768px)
and (max-device-width: 1024px)  and (orientation: landscape) {
    .text_box {
        width: 363px;
        border-radius: 4px;
        height: 40px;
    }
    .personal_info_text {
        font-size: 28px;
    }  
}

@media screen and (max-device-width: 480px){
    .text_box {
        width: 169px;
        border-radius: 4px;
        height: 40px;
    }
    .application_main {
        margin-top: 22px;
        width: 100vw;
        color: #1b1b1b;
        background-color: #f4f4f4;
        padding: 0 20px;
    }
    .personal_info_text {
        font-size: 28px;
    } 
}