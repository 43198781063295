.contact_banner {
  /* background-color: #333; */
  width: 100%;
  height: 30vh;

  background-image: url('../../assets/banner/image\ 83.png');
  background-position: center;
}
.contact_banner .shadow {
  position: absolute;
  /* backdrop-filter: blur(10px); */
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact_banner .contact_banner_title,
.contact_banner_small {
  color: #fff;
}

.contactus_section_1 {
  padding: 0 20px;
}
.contact_form_row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
}
.contactus_form_area {
  margin-left: 25px;
}
.contactus_form_area input::placeholder {
  opacity: 0.5;
  font-size: 12px;
}
.contact_sumbit_btn {
  background-color: #131313;
  color: #fff;
  padding: 10px 30px;
  outline: none;
  border: none;
}
.contact_form_row .cantact_form_row_firstspan {
  font-size: 38px;
  font-weight: bolder;
  line-height: 41px;
}
.contact_form_row .cantact_form_row_secondspan {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
}
.form_check_ {
  margin: 0;
  padding: 0;
}
.form_check_ small {
  color: black;
  font-weight: 800;
  font-size: large;
}
/* Address Section */
.contact_address {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* left Side Address Data */
.contactus_section_data {
  width: 50%;
  display: flex;
  justify-content: center;
  min-height: 350px;
}
.contactus_wrap_div {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 15px 35px;
  background-color: #e7be0d;
}
.contactus_wrap_div .contactus_wrap_div_title {
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
}
.contactus_wrap_div .contactus_btn_map {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5px;
  word-spacing: 5px;
  margin-left: 10px;
}
.contactus_wrap_div p {
  width: 80%;
  padding-top: 30px;
  font-size: 20px;
  font-weight: 400;
}

.map_icon_design {
  width: 100%;
  text-align: center;
  color: var(--white);
  border-radius: 6px;
  background-color: #131313;
  padding: 10px 0px;
}
.map_icon_design_icon {
  font-size: 1.3rem;
}
.map_icon_design span {
  color: var(--white);
}

/* right Side Address Map */

.contactus_section_map {
  width: 50%;
}
.contactus_section_map iframe {
  width: 95%;
  height: 350px;
}

.site_link_contactus {
  font-size: 25px;
}

/* @media screen and (width<=376px) {
  .contactus_form_area {
    margin-left: 0;
  }
  .contact_address {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1500px;
    flex-direction: column;
    justify-content: space-around;
    background-color: #131313;
  }
  .contactus_wrap_div {
    width: 100%;
    background-color: #fff;
  }
} */
@media screen and (width<=481px) {
  .contactus_form_area {
    margin-left: 0;
  }
}
@media screen and (width<=641px) {
  .contact_address {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1000px;
    flex-direction: column;
    justify-content: space-around;
    /* background-color: #131313; */
  }
  .contactus_section_data {
    width: 100%;
    /* height: 350px; */
    margin-bottom: 20px;
  }
}
@media screen and (width<=768px) {
  .contact_banner {
    text-align: center;
  }
  .contactus_section_data {
    width: 100%;
    margin-bottom: 20px;
  }
  .contactus_section_map {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .contactus_section_map iframe {
    width: 80%;
  }
}
@media screen and (width<=961px) {
  .contact_address {
    height: 800px;
    flex-direction: column;
    justify-content: space-around;
  }
  .contactus_section_data {
    width: 100%;
    margin-bottom: 20px;
  }
  .contactus_section_map {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .contactus_section_map iframe {
    width: 90%;
  }
}
@media screen and (width<=1200px) {
  .contactus_wrap_div {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 15px 35px;
    background-color: #e7be0d;
  }
}
