.banner_main {
  top: 0;
  position: relative;
  width: 100%;
  height: 40vh;
  display: grid;
  grid-template-columns: 2fr 0fr;
  grid-template-rows: auto;
  align-items: center;
  overflow: hidden;
}

.banner_main img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.banner_main span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(25 24 24 / 54%);
  color: white;
  font-size: 4vw;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
}

/* @media screen and (max-width: 768px) {
  .banner_main {
    grid-template-columns: 1fr; 
    grid-template-rows: auto auto; 
    height: auto; 
  }

  .banner_main img {
    position: static; 
    height: auto; 
  }

  .banner_main span {
    font-size: 6vw; 
  }
} */
