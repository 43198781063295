.mission_section_1{
    background-color: var(--white-light);
}
.mission_section_1 ul{
    width: 80%;
    font-weight: 400;
    font-size: 23.6998px;
    line-height: 44px;
    color: #333333;
    padding: 50px 0 50px 200px;
}
/* var(--gray) */
.mission_sub_heading span{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 44px;
    color: #333333;
}
.mission_card{
    display: flex;
    justify-content: center;
    gap: 30px;
}

.mission_section_3{
    display: flex;
    flex-direction: column;
}
.mission_main .mission_section_3{
    height: 50vh;
    border: 3px solid #000;
    word-wrap: break-word;
}
.mission_section_3 img{
    width: 100%;
    height: 30vh;
    border-bottom: 1.5px solid #000 ;
}
.mission_card_content{
    width: 100%;
}
.mission_button button{
    width: 94px;
    height: 28px;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.04em;
    box-sizing: border-box;
    color: #333333;
    border: 1px solid #131313;
    border-radius: 3px;
    margin: 5px 0;
}

@media screen and (max-width:767px) {
    .mission_section_1 ul{
        width: 100%;
        padding: 5px 0 0 20px;
    }
    
}

@media screen and (width:768px){
    .mission_section_1 ul {
        width: 100%;
        padding: 44px 0 50px 31px;
    }
    
}

@media screen and (width:820px) {
    .mission_section_1 ul{
        width: 100%;
        padding: 0 0 0 20px;

    }  
}


@media screen and (width:1024px){
    .mission_section_1 ul {
        width: 100%;
        padding: 46px 0 50px 40px;
    }
    
}