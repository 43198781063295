.footer_container {
  background: #000;
  padding: 20px 70px;
}
.footer_section1 {
  display: flex;
  width: 100%;
}
.footer_logo {
  width: 40%;
}
.footer_section1_left {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.footer_section1_right {
  width: 60%;
  display: flex;
  margin-top: 40px;
  justify-content: center;
}
.footer_section1_right_ul {
  list-style: none;
  margin: 10px 0;
  color: #fff;
}
.footer_section1_right_ul span {
  font-size: 1.2rem;
  font-weight: 700;
}
.footer_section1_right_ul > li {
  margin: 10px 0;
}
.footer_section1_right_ul > li > .link {
  color: #fff;
}

.footer_discription {
  font-size: 1.7rem;
  color: #fff;
  margin-top: 20px;
  word-wrap: break-word;
}
.marquee {
  color: #fff;
}
.footer_arrow {
  width: 100%;
}
.footer_arrow_line {
  display: inline-block;
  width: 92%;
  border-bottom: 3px solid #fff;
}
.footer_arrow_newArrow {
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  width: 5%;
}
.footer_bottom_link {
  width: 100%;
  background-color: #383a3b;
  padding: 0 70px;
}
.footer_bottom_link_list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.footer_Logo_link {
  color: #fff;
}
.footer_copyright {
  color: #fff;
  padding: 20px 0;
}
.footer_copyright_cool {
  color: #e7be0d;
}
@media screen and (width<=767px) {
  .footer_section1 {
    flex-direction: column;
  }
  .footer_section1_left,
  .footer_section1_right {
    width: 100%;
  }
}
@media screen and (width<=481px) {
  .footer_arrow {
    display: none;
  }
}
