@import url("https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap");

.mainDiv {
  background: url(../../assets/images/landing/TopBackgroungImage/image.png)
    lightgray 0px -33.049px / 100% 116.796% no-repeat;
  width: 100%;
  height: 700px;
  flex-shrink: 0;
}
/* .TopHeadMain {
  position: relative;
  width: 100%;
  height: 670px;

  display: flex;
  justify-content: center;
  align-items: center;
} */

.mainDiv2 {
  position: relative;
  width: 100%;
  height: 670px;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: lightgray; */
}

.background-image {
  width: 100%;
  height: 680px;
  z-index: -1;
  background-attachment: fixed;
}
/* ///////////////////////// */
/* .videoContainerShow {
  opacity: 1;
  transition: opacity 2s ease-in-out;
}

.videoContainerHide {
  opacity: 0;
  transition: opacity 2s ease-in-out;
} */

.carousel-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-dots span {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-dots .active {
  background-color: white;
}
/* /////////////////////////// */
.main_heading {
  display: flex;
  align-items: center;
  padding-top: 86px;
  padding-left: 129px;
}
.main_heading span {
  color: #fde233;
  font-size: 119.2px;
  font-family: Libre Bodoni;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 8rem;
  padding-left: 63px;
  width: 39%;
  height: 130px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  top: 23px;
}

.product_heading {
  /* font-family: Encode Sans; */
  color: rgba(0, 0, 0, 0.2);
  font-size: calc(60px + 0.390625vw);
  /* font-size: 86.111px; */
  font-weight: 700;
  line-height: 59.243px;
  letter-spacing: 0.859px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 150px;
  z-index: 1;
  position: absolute;
  transition: all 1s ease-in;
  -webkit-text-stroke: 1px white;
}

.arrow_right {
  width: 48px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: #fff;
  padding-bottom: 20px;
  -webkit-text-stroke: 1px white;
}
.arrowimg {
  width: 67px;
  height: 17px;
  margin-left: 11px;
}
.product_heading_rotate {
  color: #e5c900;
  font-family: Poppins;
  font-size: 19px;
  width: 126px;
  font-weight: 900;
  padding-top: 0px;
  height: 39px;
  line-height: 28.155px;
  letter-spacing: 3.962px;
  z-index: -1;
  text-align: center;
  padding-right: -4px;
  margin-right: 100%;
  position: absolute;
  transform: rotate(270deg);
  transition: all 1s ease-in;
}

/* .Product_card_main{
    opacity: 0;
    } */

/* .main:before {
  content: '';
  position: absolute;
  top: 10;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 150px;
  width: 527.603px;
  height: 130px;
  transform: translate3d(0, 0, 90%);
  border: 0.962px solid #ffdf01;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8.17782974243164px);
  z-index: 1;
  transition: all 1s ease-in;
} */

/* .main:hover:before {
  transform: translate3d(-92%, 0, 0);
  background-color: white;
} */
/* .main:hover .product_heading {
  opacity: 0;
  transition: all 1s ease-in;
}  */

.main .product_heading_rotate {
  z-index: 1;
  top: 47px;
  border: 0.73px solid #ffdf01;
  background: rgb(26 25 25 / 76%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main .Product_card_main {
  /* Initial state */
  z-index: 1;
  opacity: 1;
  transition: all 1s ease-in;
}

/* .main:hover .Product_card_main {
  
  z-index: 1;
  opacity: 1;
} */

.Product_card_main {
  /* opacity: 1; */
  display: flex;
  padding-right: 30px;
  flex-direction: row;
  justify-content: space-between;
}
.card1 {
  width: 137.536px;
  height: 130px;
  flex-shrink: 0;
  border: 0.73px solid #ffdf01;
  background: rgb(26 25 25 / 76%);
  margin-right: 15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  flex-direction: column;
}
.card1:hover {
  background-color: #e7be0d;
  color: #000;
}
.card1:hover .product_text {
  color: #000;
}
.cardclick:hover {
  text-decoration: none;
}

.selfchekout_image {
  height: 46px;
  flex-shrink: 0;
  padding: 10px;
}
.selfchekout_image_lido {
  height: 46px;
  flex-shrink: 0;
  padding: 0px;
  margin-right: 52px;
}
.selfchekout_image_smart {
  height: 46px;
  flex-shrink: 0;
  padding: 5px;
  margin-right: 68px;
}
.selfchekout_image_mpos {
  width: 60px;
  height: 21px;
  margin-right: 37px;
  margin-bottom: 10px;
}
.selfchekout_image_oracle {
  height: 46px;
  flex-shrink: 0;
  padding: 7px;
}
.selfchekout_image_consumer {
  height: 46px;
  flex-shrink: 0;
  padding: 5px;
  margin-right: 54px;
}

.product_text {
  color: #ffdf01;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.024px;
  opacity: 1;
}

.Top_main_dispaly_chanege_previous {
  position: absolute;
  top: 35%;
  left: 0px;
}
.Top_main_dispaly_chanege_next {
  position: absolute;
  top: 35%;
  right: 0px;
}
.videoContainerShow {
  width: 100%;
  position: relative;
  height: 90vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  object-fit: cover;
}
.videoContainerHide {
  display: none;
}
/* Hero Section Animation */
.selected {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.dismiss {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}
.Shopingshowcase {
  width: 100%;
  height: 700px;
  object-fit: fill;
  aspect-ratio: 16/9;
}
.previousbutton {
  font-weight: 900;
  font-size: 60px;
  color: #fde233;
  animation: blinker 1s linear infinite;
}
.previousbutton:hover {
  border: 2px solid #ffffff;
  cursor: pointer;
}
.nextbutton {
  font-weight: 900;
  font-size: 60px;
  margin-right: 2px;
  color: #fde233;
  animation: blinker 2s linear infinite;
}
.nextbutton:hover {
  border: 2px solid #ffffff;
  cursor: pointer;
}
@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
/* top section product and service end */

/* customer section start */
.Customer_service_main {
  padding-top: 68px;
  padding-bottom: 30px;
}

.customer_heading_main {
  background-color: #fff;
  width: 100%;
  /* height: 300px; */
}
.customer_heading_main {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.customer_heading_text {
  color: #333;
  font-family: Poppins;
  font-size: 48.883px;
  font-style: normal;
  font-weight: 400;
  line-height: 84.47px;
  padding-bottom: 14px;
}
.customer_text {
  color: #333;
  font-family: Poppins;
  font-size: 48.883px;
  font-style: normal;
  font-weight: 800;
  line-height: 84.47px;
}
.first_Container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.second_Container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.sec_cointain {
  /* padding-left: 87px; */
}
.sec_cointain2 {
  /* padding-right: 42px; */
}
.sec_cointain3 {
  /* padding-right: 46px; */
}
.container_first {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.num {
  color: #e7be0d;
  font-family: Poppins;
  font-size: 64.119px;
  font-style: normal;
  font-weight: 700;
  line-height: 66.3px;
}
.Customer_Number_Contain {
  margin-left: 72px;
}
.main_container {
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
}
.all_text {
  color: #333;
  font-family: Poppins;
  width: 295px;
  /* font-size: 18px; */
  font-size: calc(13px + 0.390625vw);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 3px;
  height: 13px;
}
.dot {
  font-size: 42px;
  color: #e7be0d;
  font-weight: 900;
}
.dot_four {
  font-size: 42px;
  color: #e7be0d;
  font-weight: 900;
}
/* customer section :end */

/* counter number for omini chaneel start */
.Omini_Cheenal_Counter_Number {
  background: linear-gradient(272deg, #606060 0.01%, #1a1a1a 100.01%);
  width: 100%;
}
.Omini_Main_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 10px 65px;
}
.Omini_Container {
  text-align: center;
  width: 196px;
  height: 245px;
}
.Number_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.first,
.second,
.third,
.fourth,
.fifth,
.odometer-value,
.odometer-digit-spacer {
  font-size: 50px;
  font-weight: 700;
  margin: 10px 0 0 0;
  height: 30px;
  color: #e7be0d;
}

.plush_sign {
  font-size: 55px;
  font-weight: 700;
  margin: 0px 0 0 0;
  height: 30px;
  color: #e7be0d;
}
.Text_dec {
  font-size: 22px;
  width: 196px;
  margin-bottom: 30px;
  color: #fff;
  padding-top: 38px;
  line-height: 31.676px;
}
.Text_dec_Omini_Container2 {
  font-size: 22px;
  width: 211px;
  margin-bottom: 30px;
  color: #fff;
  padding-top: 38px;
  line-height: 31.676px;
}
/* counter number for omini chaneel end */

/* who we Are start */
.Main_Who_weAre {
}

.Who_WeAre_Main_Container {
  padding: 40px;
}
.Who_WeAre_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.Who_WeAre_Image_container {
  width: 814px;
  height: 396px;
}
.Who_WeAre_Image {
  width: 814px;
  height: 396px;
  object-fit: cover;
}
.Who_WeAre_Mian_textContainer {
  width: 452px;
  height: 396px;
  background: #e7be0d;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Who_WeAre_Heading_text {
  color: #0a0a0a;
  font-family: Poppins;
  font-size: 34.205px;
  font-style: normal;
  font-weight: 500;
  line-height: 46.874px;
  padding-top: 10px;
}
.Who_WeAre_Bold_text {
  color: #0a0a0a;
  font-family: Poppins;
  font-size: 34.205px;
  font-style: normal;
  font-weight: 700;
  line-height: 46.874px;
}
.Who_WeAre_P {
  padding: 20px;
  color: #0a0a0a;
  font-size: calc(12px + 0.390625vw);
}
.Who_WeAre_suntech {
  font-weight: 700;
}

/* who we Are end */

/* Disrupting_main start   */
.Disrupting_main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.Disrupting_main_heading {
  height: 110px;
}
.Disrupting_main_heading_text {
  color: #333;
  font-family: Poppins;
  font-size: 48.88px;
  font-style: normal;
  font-weight: 400;
  line-height: 80.717px;
}
.Disrupting_text_retail {
  font-size: 49px;
  color: #333;
  font-weight: 700;
}
/* Disrupting_main end   */

/* Retail Image card stat  */

.Image_card {
  width: 99%;
}

.contain_image {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
}

.card_body_text {
  margin-top: 30px;
}

.contain_image .image {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  width: 100%;
  position: relative;
  height: 406px;
  left: 0;
}

.fashion_text {
  text-align: center;
  z-index: 2;
  position: absolute;
  bottom: 44%;
  left: 38%;
  font-size: 2rem;
  font-weight: 900;
  color: white;
}
.fashion_text span {
  font-size: 34px;
}
.grocery_text {
  text-align: center;
  z-index: 2;
  position: absolute;
  bottom: 45%;
  left: 39%;
  font-size: 2rem;
  font-weight: 900;
  color: white;
}
.grocery_text span {
  font-size: 34px;
}
.consumer_text {
  text-align: center;
  z-index: 2;
  position: absolute;
  bottom: 42%;
  left: 31%;
  font-size: 2rem;
  font-weight: 900;
  color: white;
}
.consumer_text span {
  font-size: 34px;
}
.furnishing_text {
  text-align: center;
  z-index: 2;
  position: absolute;
  bottom: 42%;
  left: 28%;
  font-size: 2rem;
  font-weight: 900;
  color: white;
}
.furnishing_text span {
  font-size: 34px;
}
.overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #000;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.overlay .text h1 p {
  opacity: 1;
}
.contain_image:hover .overlay {
  height: 75%;
  background-color: #0000008c;
}
.overlay_fashion {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 0;
  background-color: #000;
  overflow: hidden;
  width: 98.5%;
  height: 0;
  transition: 0.5s ease;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.landing_industry_content {
  color: #ffc700;
}
.overlay .text h1 p {
  opacity: 1;
}
.contain_image:hover .overlay_fashion {
  height: 75%;
  background-color: #0000008c;
}
.contain_image:hover .fashion_text {
  display: none;
}
.contain_image:hover .grocery_text {
  display: none;
}
.contain_image:hover .consumer_text {
  display: none;
}
.contain_image:hover .furnishing_text {
  display: none;
}
.contain_image:hover + .fashion_text {
  display: none;
}
.text {
  color: white;
  /* font-size: 20px; */
  position: absolute;
  top: 50%;
  left: 48%;
  opacity: 1;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.text span {
  font-weight: 600;
  font-size: 2rem;
  opacity: 1;
  margin-top: 30px;
}
.text p {
  margin-top: 40px;
}

/* Retail Image card end  */

/* future checkout css start  */
.head_future {
  height: 57vh;
}
.Main_future {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 122px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.head_future h3 {
  color: #131313;
  font-size: 35px;
  line-height: 43px;
  font-weight: 900;
}
.head_future h1 {
  font-weight: 700;
  font-size: 70px;
  margin-top: -16px;
  font-family: Poppins;
}
.head_future p {
  font-size: 23px;
}
.head_future p li {
  font-size: 21px;
  margin-left: 26px;
}
.future_hilight {
  font-weight: 600;
}
.Futurechekoutimg {
  width: 100%;
  height: 57vh;
}
.future_GetDemo {
  background: black;
  color: #fff;
  width: 126px;
  height: 50px;
  margin-top: 37px;
}
/* future checkout css end  */

/* Suport css start  */
.Suport_main_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-bottom: 40px;
}
.Suport_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 240px;
  height: 199px;
  flex-shrink: 0;
  border-radius: 25.253px;
  background: #131313;
}
.Suport_top_number {
  color: #e7be0d;
  text-align: center;
  font-family: Poppins;
  font-size: 54.176px;
  font-style: normal;
  font-weight: 600;
  line-height: 45.573px;
}
.Suport_all_text {
  color: #fff;
  font-family: Poppins;
  font-size: 29.462px;
  font-style: normal;
  font-weight: 500;
  line-height: 45.573px;
  text-align: center;
}

/* Suport css end  */

/* Rfid Demo css start  */
.Rfid_Demo_main {
  width: 100%;
  position: relative;
  top: 18px;
  padding-bottom: 60px;
}
.rfid_text h4 {
  font-size: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-weight: 600;
}
.rfid_text h1 {
  font-size: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  color: #333;
}
.videoImage {
  display: flex;
  justify-content: center;
}
.videoimg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rfiddemo_video {
  width: 100%;
  /* height: 100vh;
        aspect-ratio: 15/1; */
}
.play_button_video_icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #808285;
  width: 100px;
  height: 100px;
  border-radius: 100px;
}
.play_icon_leaderShip {
  font-size: 43px;
  color: white;
  margin-left: 10px;
}

/* Rfid Demo css end */

/* Main_OurReatail_Rfid css start  */

.Main_OurReatail_Rfid {
  background: linear-gradient(272deg, #fff 0.01%, #000 100.01%);
  height: 196px;
}
.Main_OurReatail_Rfid_heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #e7be0d;
  text-align: center;
  font-family: Poppins;
  font-size: 68.469px;
  font-style: normal;
  font-weight: 700;
  line-height: 129.027px;
  padding-left: 123px;
  margin: 0px 0em -55px 0em;
}
.OurReatail_Rfid_Leading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  /* font-size: 46.916px; */
  font-size: calc(40px + 0.390625vw);
  font-style: normal;
  font-weight: 700;
  line-height: 105.541px;
}
.OurReatail_Rfid_text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 125px;
}
.vertical {
  border-left: 1px solid black;
  height: 73px;
  padding-left: 30px;
  padding-right: -21px;
  opacity: 0.5;
}
.OurReatail_Rfid_image_main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 53px;
}
.OurReatail_Rfid_image_agon {
  margin-right: 30px;
  aspect-ratio: 9/1;
}

/* Main_Tag_replacement css start  */

.Main_Tag_replacement {
  padding-top: 40px;
  padding-bottom: 40px;
}

.Tag_replacement {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 0 18px;
}
.play_button_video_icon2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #808285;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  margin-left: 237px;
  margin-top: 104px;
  opacity: 0.5;
}
.play_button_video_icon3 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #808285;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  margin-left: 237px;
  margin-top: 104px;
  opacity: 0.5;
}
.play_icon_leaderShip2 {
  font-size: 35px;
  color: white;
  margin-left: 10px;
  opacity: 1;
}
.play_icon_leaderShip3 {
  font-size: 35px;
  color: white;
  margin-left: 10px;
  opacity: 1;
}
.card_title_heading {
  color: #1a1a1c;
  font-family: Poppins;
  font-size: 35.999px;
  font-style: normal;
  font-weight: 600;
  line-height: 47.203px;
}
.card_body_hover {
  background-color: #f5f5f5;
}
.card_body_hover:hover {
  background-color: #e7be0d;
  cursor: pointer;
}

/* Main_Tag_replacement css end  */

/* Accolades css start  */
.slider_main {
  background: #131313;
  width: 100%;
  height: 78vh;
  display: flex;
  justify-content: center;
}
.slide_card {
  height: 349px;
  display: flex;
  justify-content: center;
  margin-top: 0;
  width: 56vw;
}
.SlideHeading h1 {
  color: white;
  font-size: 50px;
  display: flex;
  height: 107px;
  font-weight: 600;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}
.clint-title {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 14.291px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.457px;
  letter-spacing: -0.429px;
}
.clint-title {
  width: 323px;
  /* height: 42.011px; */
  margin-bottom: 15px;
  margin-top: -5px;
}
.clint-title {
  margin-bottom: 15px;
  margin-top: -5px;
  width: 131.317px;
  /* height: 64px; */
  flex-shrink: 0;
}

.carousel_heading_image_unicomer {
  margin-bottom: 0;
  margin-top: -35px;
  width: 301px;
  height: 120px;
  flex-shrink: 0;
}
.carousel_heading_image_suntech {
  margin-bottom: 20px;
  margin-top: -5px;
  width: 301px;
  height: 78.616px;
  flex-shrink: 0;
}
.carousel_heading_image_extra {
  margin-bottom: 20px;
  margin-top: -5px;
  width: 245px;
  height: 78px;
  flex-shrink: 0;
}
.card_text_paragraph {
  padding-top: 53px;
}
.clint-designation {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 9.947px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.632px;
  letter-spacing: 0.702px;
}
.checked {
  color: orange;
}
.carousel_heading_main {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.left_button {
  font-size: 50px;
  background: none;
  border: none;
  width: 34px;
  height: 27px;
}
.prev_button {
  top: 258px;
  left: 61px;
}
.right_button {
  font-size: 50px;
  background: none;
  border: none;
  width: 34px;
  height: 27px;
}
.next_button {
  top: 258px;
  right: 61px;
}
.containor_slide {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 130px;
}
.arrowLeftLending,
.arrowRightLending {
  font-size: 45px;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

/* Accolades css end  */

/* Lets talk css start  */

.lets_talk_main {
  height: 33vh;
  padding: 25px 0;
  background: #eaeaea;
  margin-top: 34px;
}

.lets_talk_heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lets_talk_heading h1 {
  font-weight: 600;
}
.lest_paragraph_text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lets_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px;
}
.lets_buttoninside {
  width: 187px;
  border: 1px solid;
  background-color: #000;
  color: white;
}

/* Lets talk css end  */

/* Responsive css  */

@media only screen and (min-width: 1030px) and (max-width: 1400px) {
  .mainDiv {
    height: 700px;
    flex-shrink: 0;
  }
  .mainDiv2 {
    height: 700px;
    flex-shrink: 0;
  }
  .main {
    width: 50vw;
    padding-left: 53px;
    [class*="col-"] {
      width: 100%;
    }
  }
  .product_heading {
    /* font-family: Encode Sans; */
    color: rgba(0, 0, 0, 0.2);
    font-size: 86.111px;
    font-size: calc(60px + 0.390625vw);

    font-weight: 700;
    line-height: 59.243px;
    letter-spacing: 0.859px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 150px;
    z-index: 1;
    position: absolute;
    transition: all 1s ease-in;
    -webkit-text-stroke: 1px white;
  }
  .all_text {
    width: 270px;
  }
  .sec_cointain {
    padding-left: 5px;
  }
  .sec_cointain2 {
    padding-right: 0px;
  }
  .sec_cointain3 {
    padding-right: 9px;
  }
  .OurReatail_Rfid_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 69px;
    gap: 63px;
  }
  .Who_WeAre_Image_container {
    width: 600px;
  }
  .Who_WeAre_Image {
    width: 600px;
  }
  .contain_image .image {
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    width: 100%;
    position: relative;
    height: 406px;
    left: 0;
  }
  .Image_card {
    width: 98.8%;
  }
  .slider_main {
    background: #131313;
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
  }
  .Main_OurReatail_Rfid_heading {
    padding-left: 58px;
  }
  .OurReatail_Rfid_Leading {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 114.541px;
    /* margin-left: -50px; */
  }
  .lets_talk_main {
    height: 39vh;
    padding: 25px 0;
    background: #eaeaea;
    margin-top: 34px;
  }
  .Customer_service_main {
    padding-top: 99px;
    padding-bottom: 30px;
    height: 72vh;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .mainDiv {
    height: 700px;
  }
  .mainDiv2 {
    height: 700px;
  }
  .main {
    width: 72%;
  }
  .videoContainerShow {
    height: 650px;
    position: relative;
  }
  .ImageSlider {
    position: absolute;
    background-color: red;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .Top_main_dispaly_chanege {
    position: absolute;
    bottom: 5px;
    right: 33px;
  }

  .all_text {
    font-size: 13px;
    height: 10px;
    width: 165px;
    line-height: 20px;
  }
  .dot_four {
    font-size: 60px;
    color: #e7be0d;
    font-weight: 900;
  }

  .second_Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }
  .first_Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }

  .rfid_text h1 {
    font-size: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    color: #333;
  }
  .Omini_Main_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(138px, auto);
  }

  .Omini_Container {
    text-align: center;
    width: 196px;
    height: 225px;
  }
  .Who_WeAre_Image_container {
    width: 350px;
  }
  .Who_WeAre_Image {
    width: 340px;
  }
  .Main_future {
    padding: 0 34px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .Main_OurReatail_Rfid_heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #e7be0d;
    text-align: center;
    font-family: Poppins;
    font-size: 68.469px;
    font-style: normal;
    font-weight: 700;
    line-height: 154.027px;
    padding-left: 10px;
    margin: 0px 0em -55px 0em;
  }
  .OurReatail_Rfid_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
  }
  .OurReatail_Rfid_Leading {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 80.541px;
  }
  .OurReatail_Rfid_image_agon {
    margin-right: 7px;
    width: 140px;
  }
  .OurReatail_Rfid_image {
    width: 140px;
  }
  .vertical {
    border-left: 3px solid black;
    height: 60px;
    padding-left: 5px;
    /* padding-right: -21px; */
  }
  .Suport_main_container {
    margin-bottom: 40px;
    margin-left: 95px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 39px;
    grid-auto-rows: minmax(100px, auto);
  }
  .slider_main {
    background: #131313;
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
  }
  .Tag_replacement {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 0 18px;
    gap: 20px;
  }
  .containor_slide {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 130px;
    width: 100%;
  }
  .prev_button {
    top: 258px;
    left: 23px;
  }
  .next_button {
    top: 258px;
    right: 23px;
  }
  .slide_card {
    height: 425px;
    display: flex;
    justify-content: center;
    margin-top: 0;
    width: 80vw;
  }
  .Image_card {
    width: 98%;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .main {
    width: 55%;
  }
  .Who_WeAre_Main_Container {
    padding: 40px;
  }
  .Who_WeAre_Image_container {
    width: 600px;
  }
  .Who_WeAre_Image {
    width: 600px;
  }
  .Main_OurReatail_Rfid_heading {
    padding-left: 38px;
  }
  .slide_card {
    width: 64vw;
  }
  .OurReatail_Rfid_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 20px;
  }
  .OurReatail_Rfid_Leading {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 36.916px;
    font-style: normal;
    font-weight: 700;
    line-height: 114.541px;
    margin-left: 0px;
  }
}

@media screen and (max-device-width: 480px) {
  .mainDiv {
    height: 750px;
    width: 100%;
  }
  .mainDiv2 {
    height: 750px;
    width: 100%;
  }
  .Shopingshowcase {
    width: 98%;
    height: 750px;
    object-fit: fill;
    aspect-ratio: 9/16;
  }
  .main_heading {
    display: flex;
    align-items: center;
    padding-top: 86px;
    padding-left: 9px;
  }
  .main {
    margin-left: 8px;
    padding-left: 45px;
    width: 98%;
    height: 130px;
  }
  .selfchekout_image_lido {
    height: 46px;
    flex-shrink: 0;
    padding: 0px;
    margin-right: 38px;
  }
  .selfchekout_image_smart {
    height: 46px;
    flex-shrink: 0;
    padding: 5px;
    margin-right: 45px;
  }
  .selfchekout_image_mpos {
    width: 60px;
    height: 21px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .selfchekout_image_consumer {
    height: 46px;
    flex-shrink: 0;
    padding: 5px;
    margin-right: 40px;
  }
  .main:before {
    content: "";
    position: absolute;
    top: 10;
    right: 0;
    bottom: 0;
    left: 0;
    padding-left: 150px;
    width: 360.603px;
    height: 250px;
  }
  .Product_card_main {
    opacity: 1;
    display: flex;
    padding-right: 65px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .product_heading {
    /* font-family: Encode Sans; */
    color: rgba(0, 0, 0, 0.2);
    font-size: 50.111px;
    font-weight: 700;
    line-height: 44.243px;
    letter-spacing: 0.859px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 80px;
    z-index: 1;
    position: absolute;
    transition: all 1s ease-in;
    -webkit-text-stroke: 1px white;
    width: 86%;
  }
  .arrowimg {
    width: 50px;
    height: 17px;
    margin-left: 11px;
  }
  .card1 {
    width: 89.536px;
    height: 96px;
    margin-right: 7px;
    z-index: 1;
  }
  .selfchekout_image {
    height: 40px;
    flex-shrink: 0;
    padding: 10px;
  }
  .Top_main_dispaly_chanege_previous {
    position: absolute;
    top: 80%;
    left: 0px;
  }
  .Top_main_dispaly_chanege_next {
    position: absolute;
    top: 80%;
    right: 0px;
  }
  .product_text {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 700;
    line-height: 20.024px;
    opacity: 1;
  }
  .Omini_Cheenal_Counter_Number {
    width: 100%;
  }
  .Omini_Main_container {
    display: grid;
    grid-template-columns: repeat(2, 3fr);
    gap: 10px;
    grid-auto-rows: minmax(138px, auto);
  }
  .Omini_Container {
    text-align: center;
    width: 167px;
    height: 199px;
  }

  .Text_dec {
    font-size: 16px;
    width: 171px;
    margin-bottom: 30px;
    color: #fff;
    padding-top: 38px;
    line-height: 31.676px;
  }
  .Text_dec_Omini_Container2 {
    font-size: 16px;
    width: 171px;
    margin-bottom: 30px;
    color: #fff;
    padding-top: 38px;
    line-height: 31.676px;
  }
  .Who_WeAre_Mian_textContainer {
    width: 360px;
    height: 390px;
    background: #e7be0d;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .Who_WeAre_Image {
    width: 360px;
    height: 390px;
    object-fit: fill;
    background-repeat: no-repeat;
  }
  .Who_WeAre_Image_container {
    width: 360px;
    height: 390px;
  }
  .Main_future {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column;
  }
  .Who_WeAre_Container {
    display: grid;
  }
  .Disrupting_main_heading_text {
    color: #333;
    font-family: Poppins;
    font-size: 41.88px;
    font-style: normal;
    font-weight: 400;
    line-height: 59.717px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Image_card {
    width: 96.5%;
  }
  .fashion_text {
    bottom: 54%;
    left: 34%;
  }
  .grocery_text {
    bottom: 48%;
    left: 31%;
  }
  .consumer_text {
    bottom: 57%;
    left: 25%;
  }
  .furnishing_text {
    bottom: 42%;
    left: 15%;
  }
  .overlay_fashion {
    width: 96.5%;
  }
  .overlay {
    width: 96.5%;
  }
  .head_future {
    height: 68vh;
  }
  .head_future h1 {
    font-weight: 700;
    font-size: 55px;
    margin-top: -16px;
  }
  .future_GetDemo {
    background: black;
    color: #fff;
    width: 126px;
    height: 50px;
    margin-top: 17px;
  }
  .Customer_Number_Contain {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 62%;
  }
  .customer_heading_text {
    text-align: center;
  }
  .first_Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 53%;
    align-items: center;
  }
  .second_Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
  }
  .all_text {
    color: #282828;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    height: 10px;
    width: 232px;
  }
  .sec_cointain {
    /* padding-left: 15px; */
  }
  .sec_cointain2 {
    /* padding-left: 40px; */
  }
  .sec_cointain3 {
    /* padding-left: 45px; */
  }
  .container_first {
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    padding-right: 58px;
    width: 312px;
  }
  .Suport_main_container {
    display: grid;
    gap: 10px;
    grid-auto-rows: minmax(138px, auto);
  }
  .dot_four {
    font-size: 60px;
    color: #e7be0d;
    font-weight: 900;
  }
  .rfid_text h1 {
    font-size: 35px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Main_OurReatail_Rfid {
    /* width: 100%; */
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 217px;
  }
  .OurReatail_Rfid_text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 0 0px;
    width: 94%;
  }
  .OurReatail_Rfid_image_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 45px;
    margin-top: -40px;
    margin-right: 20px;
  }
  .vertical {
    border-left: 1px solid black;
    height: 47px;
    padding-left: 11px;
    padding-right: -21px;
    opacity: 0.5;
  }
  .Main_OurReatail_Rfid_heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #e7be0d;
    text-align: center;
    font-family: Poppins;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 61px;
    padding-left: 35px;
    width: 93%;
  }
  .OurReatail_Rfid_Leading {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 31.916px;
    font-style: normal;
    font-weight: 700;
    line-height: 161.541px;
    padding-left: 27px;
  }
  .OurReatail_Rfid_image_agon {
    /* margin-right: 14px; */
    /* text-align: center; */
    width: 150px;
    height: 52px;
  }
  .OurReatail_Rfid_image {
    width: 150px;
    height: 60px;
  }
  .Tag_replacement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .play_button_video_icon2 {
    display: none;
  }
  .play_button_video_icon3 {
    display: none;
  }
  .carousel_heading_main {
    display: -webkit-flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .firstname {
    -webkit-order: 2;
  }
  .startimag {
    -webkit-order: 2;
  }
  .carousel_heading_image_suntech {
    margin-bottom: 20px;
    margin-top: -5px;
    width: 255px;
    height: 68.616px;
    flex-shrink: 0;
  }
  .SlideHeading h1 {
    color: white;
    font-size: 24px;
    display: flex;
    height: 107px;
    font-weight: 600;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
  }
  .slider_main {
    background: #131313;
    width: 100%;
    height: 129vh;
    display: flex;
    justify-content: center;
  }
  .containor_slide {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 100px 130px;
  }
  .slide_card {
    height: 95vh;
    display: flex;
    justify-content: center;
    margin-top: 0;
    width: 90vw;
  }
  .carousel_heading_image {
    width: 153px;
    height: 42.011px;
    margin-bottom: 20px;
    margin-top: -5px;
  }
  .clint-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16.291px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.457px;
    letter-spacing: -0.429px;
    margin-left: 110px;
  }

  .prev_button {
    top: 890px;
    left: 61px;
  }
  .next_button {
    top: 890px;
    right: 61px;
  }
  .clint-designation {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 9.947px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.632px;
    letter-spacing: 0.702px;
  }
  .lets_talk_main {
    height: 39vh;
    padding: 25px 0;
    background: #eaeaea;
    margin-top: 34px;
  }
  .lets_talk_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .lest_paragraph_text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2200px) {
  /* .main:hover .product_heading {
    opacity: 0;
    transition: all 1s ease-in;
} */

  .product_heading {
    /* font-family: Encode Sans; */
    color: rgba(0, 0, 0, 0.2);
    font-size: calc(60px + 0.390625vw);
    /* font-size: 86.111px; */
    font-weight: 700;
    line-height: 59.243px;
    letter-spacing: 0.859px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 289px;
    z-index: 1;
    position: absolute;
    transition: all 1s ease-in;
    -webkit-text-stroke: 1px white;
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 8rem;
    padding-left: 63px;
    width: 34%;
    height: 130px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}
