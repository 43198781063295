.Technical_container {
  background-color: red;
}
.Technical_main {
  background-color: pink;
}
.Technical_left {
  background-color: black;
  padding: 0;
  position: relative;
}
.Technical_left img {
  width: 100%;
  height: 60vh;
  object-fit: contain;
  aspect-ratio: 16/9;
}
.Technical_left video {
  width: 100%;
  height: 60vh;
  object-fit: contain;
  aspect-ratio: 16/9;
}
.Technical_buttom {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.44);
  backdrop-filter: blur(11.673728942871094px);
  width: 100%;
  padding: 10px 0 10px 0;
}
.Technical_buttom span {
  color: white;
}
.Technical_right {
  background-color: #eee;
  position: relative;
}
.Technical_right2 {
  background-color: rgb(172, 171, 171);
  position: relative;
}
.dots_container {
  display: flex;
  gap: 5px;
  padding: 0;
  position: absolute;
  bottom: 10px;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
.dots_container .dots {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #f5f7fa;
}
.dots_container .dotsPre {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #99999a;
}
.arrow_container {
  position: absolute;
  padding: 0;
  right: 20px;
  bottom: 10px;
}
.arrow_left_image {
  margin-right: 20px;
}
.arrow_left_image1 {
  margin-right: 20px;
  mix-blend-mode: exclusion;
}
.arrow_right_image1 {
  mix-blend-mode: exclusion;
}
.Technical_main_data_section {
  padding: 20px;
}
.Technical_main_data_section2 {
  padding: 20px;
}
.Technical_main_data_section strong {
  color: #000;
  font-family: Poppins;
  font-size: 23.186px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Technical_main_data_section2 strong {
  color: black;
  font-family: Poppins;
  font-size: 23.186px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Technical_right p {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}
.Technical_main_data_section p {
  color: #000;
  font-family: Poppins;
  font-size: 18.839px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.Technical_main_data_section2 p {
  color: black;
  font-family: Poppins;
  font-size: 18.839px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .dots_container {
    width: 80%;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .dots_container {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .dots_container {
    width: 80%;
  }
  .Technical_main_data_section p {
    font-size: 16px;
    text-align: justify;
  }
  .Technical_main_data_section strong {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 1200px) and (max-width: 1919px) {
}
@media (max-width: 1920px) {
}
