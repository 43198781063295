.textmarquee {
  width: 100%;
  height: 46px;
  background-color: #131313;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Alliances_Main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Alliances_Image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 342.08px;
  height: 229.276px;
  border: 0.917px solid #e7e7e7;

  background: #fff;
  margin: 10px;
}
.Alliances_Image img {
  width: 278.8px;
  height: 62.989px;
  padding: 10px;
  object-fit: contain;
}
.Alliances_Image:hover {
  border: 0.917px solid #f5eb8f;
  box-shadow: 0px 0px 8px 0px #f5eb8f;
  transform: scale(1.1);
  transition: 0.3s ease-out;
}
