.small_cards{
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 50px 0;
    border-bottom: 1px solid #9B9B9B;
    height: 50%;
}

.small_cards_main{
    width: 100%;
    margin: 10px;
    border: 1px solid black;
    height: 437px;


}
.small_cards_main_data_img{
    padding: 0;
}
.small_cards_main_data_img img{
    width: 100%;
}
.small_cards_main_data{
    display: flex;
    flex-direction: column;
    margin: 10px;

}
.small_cards_main_data img{
width: 100%;
}

.small_cards_main_data .small_card_tittle{
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    color: var(--black);
}

.small_cards_main_data .small_card_paragraph{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: var(--gray);
    width: 90%;
    /* text-align: justify; */
}
.small_cards_main_data button{
   width: 30%;
    height: 30px;
    font-weight: 400;
    font-size: 11.0133px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: var(--black);
    border-radius: 5px;
    border: 1px solid black;
    background-color: var(--white);

}

@media screen and (max-width:767px) {
 .small_cards{
    flex-direction: column;
 }
 .small_cards_main_data button{
    width: 35%;
 }
}

@media screen and (width: 768px) {
    .small_cards {
        width: 80%;
    }
    .small_cards_main_data .small_card_paragraph {
        width: 80%;
    }
    
}

@media screen and (width:820px) {
    .small_cards_main_data .small_card_paragraph {
        width: 95%;
    }
    .small_cards_main_data button {
        width: 41%;}
}

@media screen and (width:1180px) {
    .small_cards_main_data .small_card_paragraph {
        width: 95%;
    }
}


