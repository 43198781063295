.about_section_1 {
  font-size: 24px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--gray);
  background: #e7be0d;
  margin-left: -1px;
}
.sections_1_data {
  text-align: left;
  font-size: 1.4rem;
  color: #000;
  font-weight: normal;
}
.about_section_1 .about_high_data {
  color: #000;
  font-weight: 600;
  line-height: 44px;
  font-size: 1.5rem;
}

.imageAbout {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0;
}
.imageAbout img {
  object-fit: cover;
  /* aspect-ratio: 16/9; */
  width: 100vw;
}
.MVC_Container {
  padding: 75px 100px 75px 100px;
  background-color: black;
  transform: scale(1, 1);
}
.sections_1_data {
  color: #000;
}
.Mvc_Main {
  border-right: 1px solid #cacaca;
}

.Mvc_Main span {
  color: #fff;
  font-family: Poppins;
  font-size: 15.906px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.07px;
  width: 30px;
  z-index: 5;
}

.overlap-group {
  height: 110px;
  position: relative;
  width: 230px;
}

.main-article-section {
  color: #e7be0d;
  font-family: 'Poppins-Bold', Helvetica;
  font-size: 68px;
  font-weight: 700;
  height: 71px;
  left: 0;
  letter-spacing: 0;
  line-height: 70.3px;
  position: absolute;
  top: 39px;
  white-space: nowrap;
}

.text-wrapper {
  color: #ffffff;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 27px;
  font-weight: 600;
  height: 77px;
  left: 75px;
  letter-spacing: 0;
  line-height: 38.2px;
  position: absolute;
  top: 0;
}

.about-page-bg-png {
  position: absolute;
  top: 0;
  z-index: -1;
}

.Mvc_Main {
  position: relative;
}

.about-us-bg-png img {
  position: absolute;
  top: 0;
  right: 0;
}
.about-page-bg-png {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.about-page-bg-png img {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.5;
}

.OurLeaderMain {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  background-image: url('../../assets/images/pseudo.png');
  border: 2px solid #e7be0d;

  padding: 14px;
}
.OurLeaderMain span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 5px;
}
.leader {
  display: flex;
  flex-direction: column;

  /* border-bottom: 2px solid #cacaca; */
  /* filter: drop-shadow(0px 10px 10px #cacaca); */
}

.leader video {
  aspect-ratio: 16/9;
  object-fit: cover;
}
.LedderInfo {
  display: flex;
  gap: 20px;
  align-items: center;
}
.LeaderDetails {
  display: flex;
  flex-direction: column;
}
.LeaderDetails small {
  color: #333;
  font-family: Poppins;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 35.035px;
}
.LeaderExp {
  color: #333;

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  background-color: transparent;
}
.OurTeamMain .OurTeamHeading {
  display: flex;
  justify-content: center;
  color: #333;
  font-family: Poppins;
  font-size: 46.233px;
  font-style: normal;
  font-weight: 400;
  line-height: 79.891px;
}

.OurTeam {
  position: relative;
  overflow: hidden;
}

.TeamDetails .shadowMain {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 88%;
  padding: 15px;
  text-align: center;
  color: #fff;
  transition: all 0.1s ease-out 0.2s;
  opacity: 0;
}

.TeamDetails:hover .shadowMain {
  opacity: 1;
  transform: translateY();
  background-color: #2e2e2ecf;
  /* background-color: red; */
}
.TeamDetails {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.text-h1 {
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out;
}

.text-p {
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out 0.2s;
  color: #e7be0d;
}

.link-a {
  display: inline-block;
  color: #fff;
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out 0.2s;
}

/* .shadowMain .link-a:hover {
  background: red;
} */

.TeamDetails:hover .shadowMain .text-h1 {
  opacity: 1;
  transform: translateY(0);
}

.TeamDetails:hover .shadowMain .text-p {
  opacity: 1;
  transform: translateY(0);
  color: #e7be0d;
  margin-bottom: 10px;
}

.TeamDetails:hover .shadowMain .link-a {
  opacity: 1;
  transform: translateY(0);
}

.OurLeaderMain strong {
  font-size: 2rem;
}
.LedderInfo .linkdin_icon_main {
  width: 45px;
  height: 50px;
}
@media screen and (max-width: 767px) {
  .about_section_1 {
    justify-content: flex-start;
    width: 100%;
    font-size: 17px;
    line-height: 21px;
    padding: 10px;
  }
  .sections_1_data {
    padding: 0;
  }
  .about_section_2 {
    height: 17vh;
  }
  .about_card {
    margin: 10px;
  }
  .about-page-bg-png img {
    width: 100vw;
  }

  .MVC_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 0;
  }
  .Mvc_Main {
    border-right: none;
    border-bottom: 1px solid #cacaca;
    margin-bottom: 50px;
    width: 96vw;
    padding: 10px;
  }
  .OurLeaderMain {
    gap: 10px;
  }
  .OurLeaderMain span {
    font-size: 15px;
  }
  .banner_main span {
    font-size: 30px;
  }
  .LeaderExp {
    text-align: center;
  }
  .imageAbout {
    justify-content: center;
  }
}
@media screen and (width: 768px) {
  .about_section_2 {
    height: 23vh;
  }
  .sections_1_data {
    padding: 0;
  }

  .about-page-bg-png img {
    width: 100vw;
  }
  .imageAbout {
    justify-content: center;
  }
  .MVC_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 0;
  }
  .Mvc_Main {
    border-right: none;
    border-bottom: 1px solid #cacaca;
    margin-bottom: 50px;
    width: 96vw;
  }
}
@media screen and (width: 820px) {
  .sections_1_data {
    padding: 0;
  }
  .about_section_2 {
    height: 21vh;
  }
}
@media screen and (width: 1180px) {
  .about_section_2 {
    height: 45vh;
  }
}

/* --------------------------------------------------------- */

@media screen and (max-device-width: 767px) and (orientation: landscape) {
  .about_section_2 {
    height: 56vh;
  }
}

@media screen and (width: 1024px) {
  .imageAbout img {
    object-fit: cover;
    width: 49vw;
  }

  .about_section_1 {
    font-size: 0;
    line-height: 30px;
    margin-left: -1px;
  }
}

@media screen and (width: 1180px) {
  .imageAbout img {
    object-fit: cover;
    width: 49vw;
  }

  .about_section_1 {
    font-size: 0;
    line-height: 30px;
    margin-left: -1px;
  }
}
