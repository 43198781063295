.hero {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* max-width: 100%; */
  height: 220px;
  max-height: 720px;
  /* margin: 0 auto; */
  color: #fff;
  text-align: center;
}

.hero__image-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 223px;
  background: black;
  z-index: 0;
  opacity: 1;
}

#hero__image {
  height: 223px;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.hero__text {
  z-index: 1;
  max-width: 100%;
  padding: 20px 5%;
}

.hero__text h1 {
  margin: 0 0 24px;
  font-family: Poppins;
  font-size: 50px;
  line-height: 1.3;
}
@media only screen and (max-width: 768px) {
  .hero__text h1 {
    margin: 0 0 16px;
    font-size: 40px;
  }
}

.pageurl {
  width: 100%;
  height: 37px;
  flex-shrink: 0;
  background: #f6f6f6;
  position: relative;
}

.cardContainer_main {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 26px;
  justify-content: center;
  grid-auto-rows: 425px;
  width: 358px;
  width: 100%;
  max-width: 100%;
  /* max-height: 720px; */
  flex-direction: row;
  padding: 0px 94px 45px 359px;
  /* width: 100%; */
  align-items: center;
}
.cardContainer_main2 {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0px 95px;
  width: 100%;
}
.casestudycardmain {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 33.5%;
  height: 365px;
}
.casestudycard {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 346.878px;
  height: 400.194px;
}

.card__image-wrapper {
  display: flex;
  position: absolute;
  width: 380px;
  height: 311.946px;
}

#card__image {
  width: 400.878px;
  height: 330.946px;
  object-fit: cover;
  padding-bottom: 20px;
}

.card__text {
  z-index: 1;
  /*   max-width: 100%; */
  padding: 22px 5%;
  display: flex;
  width: 379.878px;
  height: 62.552px;
  padding: 8.124px;
  justify-content: center;
  align-items: center;
  gap: 8.124px;
  flex-shrink: 0;
  background: rgba(0, 56, 255, 0.76);
}

.card__text h1 {
  margin: 10 0 24px;
  font-family: "Merriweather", serif;
  font-size: 40px;
  line-height: 1.3;
  color: white;
}
.testmain {
  width: 379.628px;
  height: 39px;
}
.testmain p {
  color: #333;
  font-family: Poppins;
  font-size: 20.247px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.readmore {
  color: #0e39d8;
  font-family: Poppins;
  font-size: 20.247px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media only screen and (max-width: 768px) {
  .card__text h1 {
    margin: 0 0 16px;
    font-size: 40px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .cardContainer_main {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 26px;
    justify-content: center;
    grid-auto-rows: 457px;
  }
  .casestudycardmain {
    width: 24.5%;
    height: 365px;
  }
  .testmain {
    width: 340.628px;
    height: 39px;
  }
  .casestudycard {
    width: 320.878px;
  }
  #card__image {
    width: 340.878px;
  }
  .card__text {
    width: 340px;
  }
}
@media screen and (max-device-width: 480px) {
  .cardContainer_main {
    display: contents;
    /* grid-template-columns: repeat(1, 2fr);
        gap: 24px; */
    /* justify-content: center;
        grid-auto-rows: 384px; */
  }
  .casestudycardmain {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 492px;
    padding-bottom: 39px;
  }
  .testmain {
    width: 336.628px;
    height: 39px;
  }
  /* .casestudycard {
        width: 320.878px;
    } */
  #card__image {
    width: 343.878px;
    height: 330.946px;
    object-fit: cover;
    padding-bottom: 20px;
  }
  .card__text {
    width: 343px;
  }
}
