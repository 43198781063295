.suntechgallery__heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.suntechgallery__heading__data {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 86px;
  color: #333333;
}
.suntechgallery__main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gallery_video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.gallery_icon_leaderShip {
  font-size: 43px;
  color: white;
  margin-left: 40px;
  margin-top: 18px;
}
.gallery_button_video_icon {
  position: absolute;
  width: 125px;
  height: 85px;
  /* margin: 100px 0 0 200px; */
  border-radius: 15%;
  opacity: 0.8;
  border: 2px solid white;
  background-color: rgb(86 77 77 / 53%);
}
ul.suntechgallery_current_status {
  font-weight: 400;
  font-size: 20.8852px;
  line-height: 36px;
  /* or 173% */
}
ul.suntechgallery_current_status li {
  list-style: none;
}
.pill {
  background-color: #cbcbcb;
  width: 100%;
  height: 6px;
  border-radius: 5px;
}

.pill.active {
  background-color: #0072ff;
  width: 115%;
  height: 6px;
  border-radius: 5px;
  z-index: 1;
}
.suntechgallery__main {
  color: #cbcbcb;
}
.suntechgallery__main.active {
  color: #0072ff;
}
.suntechgallery_main_section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aqua;
}

@media screen and (max-width: 767px) {
  .galleryrow .suntechgallery__main {
    flex-direction: column;
    width: 50%;
  }
  .pill {
    width: 120%;
  }
  .pill.active {
    width: 140%;
  }
  .suntechgallery__heading__data {
    font-size: 35px;
  }
}

@media screen and (width: 768px) {
  .galleryrow .suntechgallery__main {
    flex-direction: column;
    width: 50%;
  }
  .pill {
    width: 138%;
  }
  .pill.active {
    width: 140%;
  }
  .suntechgallery__heading__data {
    font-size: 35px;
  }
}

@media screen and (width: 820px) {
  .galleryrow .suntechgallery__main {
    flex-direction: column;
    width: 50%;
  }
  .pill {
    width: 138%;
  }
  .pill.active {
    width: 140%;
  }
  .suntechgallery__heading__data {
    font-size: 35px;
  }
}
