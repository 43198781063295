  /* Tab Container */
  .Tabs {
    width: 100%;
    height: auto;
    position: relative;
    /* margin: 0.5rem auto 1.5rem; */
    padding: 2rem 1rem;
    
    color: #E8F0F2;
  }
  
  /* Tab Navigation */
  ul.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 3px 3px rgb(0 0 0 / 0.2);
    top: 0px;
    left: 0;
    position: absolute;
    padding: 0 520px;
    font-size: 22px;
    font-weight: 600;
  }
  
  ul.nav li {
    width: 50%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
    color: #a3a3a3;
  }
  ul.nav li:nth-child(2) {
    border-radius: 0;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }

  ul.nav li.active {
    color: #333;
    background-color: var(--white);
    /* text-decoration: underline; */
  }
  .outlet{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 102%;
    margin-left: -14px;
    background-color: #f4f4f4;
    margin-top: 36px;

  }

  @media screen and (min-device-width: 768px)
    and (max-device-width: 1024px)  and (orientation: portrait) {
      ul.nav {
        padding: 0 99px;
        font-size: 22px;
        font-weight: 600;
    }
    .outlet {
      width: 102%;
      margin-left: -8px;
      margin-top: 45px;
    }
    
    }

    @media screen and (min-device-width: 768px)
    and (max-device-width: 1024px)  and (orientation: landscape) {
      ul.nav {
        padding: 0 99px;
        font-size: 22px;
        font-weight: 600;
    }
    .outlet {
      width: 102%;
      margin-left: -8px;
      margin-top: 45px;
    } 
    }
   
    @media screen and (max-device-width: 480px){
      ul.nav {
        padding: 0 0px;
        font-size: 22px;
        font-weight: 600;
    }
    .outlet {
      margin-left: -4px;
      margin-top: 16px;
      background-color: white;
    }
    }
