.header_Container {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.5);
}
.header_main {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  background-color: transparent;
}
.header_main nav {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_main nav .header_logo {
  width: 265px;
}

/* list of Services */
#nav-menu {
  margin-top: 10px;
}

.header_main nav ul {
  display: flex;
  gap: 20px;
}

.header_main nav ul li {
  list-style: none;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  position: relative;
}
.creative {
  font-size: 1.7rem;
  font-family: funny;
  font-weight: 600;
  background: linear-gradient(
    to right,
    #f7804b,
    #f6c82a,
    #58d500,
    #0ef20a,
    #08dd84,
    #0927e6,
    #6b05d7,
    #60037f
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* text-shadow: 1px 1px 2px red, 0 0 b1em lue, 0 0 0.2em blue; */
}
.creative1Word {
  font-size: 1.7rem;
  font-family: funny;
}
.header_main nav ul .li_Of_About:hover .header_dropdown_menu,
.header_main nav ul .li_Of_creative:hover .header_dropdown_menu2 {
  display: block;
}

.header_main nav ul li a {
  color: var(--black);
  text-decoration: none;
}
.header_dropdown_menu,
.header_dropdown_menu2 {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 99;
  width: 200px;
  background-color: #fff;
  text-align: left;
}
.header_dropdown_menu li,
.header_dropdown_menu2 li {
  padding-left: 20px;
  list-style: none;
  margin-bottom: 5px;
  transition: all 0.5s ease-in-out;
}
.header_dropdown_menu li:hover,
.header_dropdown_menu2 li:hover {
  background-color: #ffdf01;
  cursor: pointer;
}
.header_dropdown_menu li:hover .subLink,
.header_dropdown_menu2 li:hover .subLink {
  font-weight: 800;
}

/* For Small Device */
.mobile_navigation {
  position: absolute;
  z-index: 99;
  top: 100%;
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: #eee;
}
.mobile_navigation_ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mobile_navigation_ul li {
  margin-top: 30px;
  width: 300px;
  list-style: none;
  text-transform: capitalize;
  text-align: left;
  font-size: 2rem;
}

.header_hamburger {
  display: none;
  position: relative;
}
.header_bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--black);
}
.header_bar_close1 {
  position: absolute;
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transform: rotate(45deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--black);
}
.header_bar_close2 {
  position: absolute;
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transform: rotate(140deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--black);
}
.header_nav-link.active {
  font-weight: 800;
  text-decoration: none;

  /* color: blue; */
}
/* Responsive Design */
@media (width <=998px) {
  .header_hamburger {
    display: initial;
  }
  .header_main.active .header_hamburger {
    display: none;
  }
  .header_main {
    padding: 0 20px;
  }
  .header_main nav .header_logo {
    margin-top: 15px;
    width: 200px;
  }

  #nav-menu {
    display: none;
  }
  .header_main.active #nav-menu {
    display: initial;
  }
}

@media screen and (width: 1024px) {
  .header_main {
    padding: 13px 60px;
  }
}

@media screen and (max-device-width: 767px) and (orientation: landscape) {
  .header_main {
    height: 21vh;
  }
}

@media screen and (min-device-width: 844px) and (max-device-width: 950px) and (orientation: landscape) {
  .header_main {
    height: 18vh;
  }
}
